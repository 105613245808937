import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '@/views/layout/home.vue'
import wx from 'weixin-jsapi'
const routes = [
    {
        path: '/admin',
        component: () => import('@/views/layout/admin'),
        children: [
            {
                path: '/admin/enter',
                name: 'adminEnter',
                component: () => import('@/views/admin/enter.vue'),
                meta: { title: '首页', /* isloa: true */ }
            },
            {
                path: '/admin/login',
                name: 'adminLogin',
                component: () => import('@/views/admin/login.vue'),
                meta: { title: '登录', /* isloa: true */ }
            },
            {
                path: '/admin/home',
                name: 'adminHome',
                component: () => import('@/views/admin/home.vue'),
                meta: { title: '多元升学规划测评', isloa: true }
            },
            {
                path: '/admin/voucher/VoucherList',
                name: 'VoucherList',
                component: () => import('@/views/admin/voucher/VoucherList.vue'),
                meta: { title: '测评券发放', isloa: true }
            },
            {
                path: '/admin/voucher/DispatchVoucher',
                name: 'DispatchVoucher',
                component: () => import('@/views/admin/voucher/DispatchVoucher.vue'),
                meta: { title: '测评券发放', isloa: true }
            },
            {
                path: '/admin/voucher/DispatchSuccess',
                name: 'DispatchSuccess',
                component: () => import('@/views/admin/voucher/DispatchSuccess.vue'),
                meta: { title: '发券成功', isloa: true }
            },
            {
                path: '/admin/tool/index',
                name: 'Toolindex',
                component: () => import('@/views/admin/tool/index.vue'),
                meta: { title: '择校辅助工具', isloa: true }
            },
            {
                path: '/admin/tool/info',
                name: 'Toolinfo',
                component: () => import('@/views/admin/tool/info.vue'),
                meta: { title: '择校辅助工具', isloa: true }
            }
        ]
    },



    /* 学生 */

    {
        path: '/user',
        component: () => import('@/views/layout/user'),
        children: [
            {
                path: '/student/enter',
                name: 'studentEnter',
                component: () => import('@/views/student/enter.vue'),
                meta: { title: '多元升学规划测评', }
            },
            {
                path: '/student/login',
                name: 'studentLogin',
                component: () => import('@/views/student/login.vue'),
                meta: { title: '登录', }
            },
            {
                path: '/student/register',
                name: 'studentReg',
                component: () => import('@/views/student/register.vue'),
                meta: { title: '登录', isloa: true }
            },
            {
                path: '/student/home',
                name: 'studentHome',
                component: () => import('@/views/student/home.vue'),
                meta: { title: '多元升学规划测评', isloa: true }
            },
            {
                path: '/student/list_info',
                name: 'studentInfo',
                component: () => import('@/views/student/list_info.vue'),
                meta: { title: '多元升学规划测评', isloa: true }
            },
            {
                path: '/student/topic',
                name: 'studentTopic',
                component: () => import('@/views/student/topic.vue'),
                meta: { title: '多元升学规划测评', isloa: true }
            },
            /* 我的 */
            {
                path: '/student/user',
                name: 'studentUser',
                component: () => import('@/views/student/user/user.vue'),
                meta: { title: '我的', isloa: true }
            },
            /* 我的测评券 */
            {
                path: '/student/user/MyVoucher',
                name: 'MyVoucher',
                component: () => import('@/views/student/user/MyVoucher.vue'),
                meta: { title: '我的测评券', isloa: true }
            },
            {
                path: '/student/user/edit_user',
                name: 'studentEuser',
                component: () => import('@/views/student/user/edit_user.vue'),
                meta: { title: '个人信息', isloa: true }
            },
            {
                path: '/student/user/report',
                name: 'studentReport',
                component: () => import('@/views/student/user/report.vue'),
                meta: { title: '报告列表', isloa: true }
            },
            {
                path: '/student/user/order',
                name: 'studentOrder',
                component: () => import('@/views/student/user/order.vue'),
                meta: { title: '订单管理', isloa: true }
            },
            {
                path: '/student/user/we',
                name: 'studentWe',
                component: () => import('@/views/student/user/we.vue'),
                meta: { title: '关于我们', isloa: true }
            },
            {
                path: '/student/user/address',
                name: 'studentAddress',
                component: () => import('@/views/student/user/address.vue'),
                meta: { title: '收货地址', isloa: true }
            },
            {
                path: '/student/user/addAddress',
                name: 'studentAddAddress',
                component: () => import('@/views/student/user/addAddress.vue'),
                meta: { title: '添加收货地址', isloa: true }
            },
            {
                path: '/student/user/card',
                name: 'studentCard',
                component: () => import('@/views/student/user/card.vue'),
                meta: { title: '会员中心', isloa: true }
            },
            /*  */
            {
                path: '/student/rpt/hld_rpt',
                name: 'student_hld_rpt',
                component: () => import('@/views/student/rpt/hld_rpt.vue'),
                meta: { title: '霍兰德职业兴趣测试结果', isloa: true }
            },
            {
                path: '/student/rpt/mbti_rpt',
                name: 'student_mbti_rpt',
                component: () => import('@/views/student/rpt/mbti_rpt.vue'),
                meta: { title: 'MBTI性格类型', isloa: true }
            },
            {
                path: '/student/rpt/zy_rpt',
                name: 'student_zy_rpt',
                component: () => import('@/views/student/rpt/zy_rpt.vue'),
                meta: { title: '卓越体系测试结果', isloa: true }
            },
            {
                path: '/student/rpt/kte_rpt',
                name: 'student_kte_rpt',
                component: () => import('@/views/student/rpt/kte_rpt.vue'),
                meta: { title: '卡特尔16PF个性测试结果', isloa: true }
            },
            {
                path: '/student/rpt/xknl_rpt',
                name: 'student_xknl_rpt',
                component: () => import('@/views/student/rpt/xknl_rpt.vue'),
                meta: { title: '学科能力测评结果', isloa: true }
            },
            // 服务
            {
                path: '/student/fw/serve',
                name: 'studentFwServe',
                component: () => import('@/views/student/fw/serve.vue'),
                meta: { title: '多元升学规划', isloa: false }
            },
            {
                path: '/student/fw/info',
                name: 'studentFwInfo',
                component: () => import('@/views/student/fw/info.vue'),
                meta: { title: '多元升学规划详情', isloa: false }
            },
            {
                path: '/student/fw/pay',
                name: 'studnetFwPay',
                component: () => import('@/views/student/fw/pay.vue'),
                meta: { title: '订单详情', isloa: false }
            },
            {
                path: '/student/fw/pay_ok',
                name: 'studnetFwPayOK',
                component: () => import('@/views/student/fw/pay_ok.vue'),
                meta: { title: '订单详情', isloa: false }
            },
            {
                path: '/student/career/index',
                name: 'studentCarindex',
                component: () => import('@/views/student/career/index.vue'),
                meta: { title: '北大生涯规划师培训', isloa: false }
            },
            {
                path: '/student/career/success',
                name: 'studentCarSuc',
                component: () => import('@/views/student/career/success.vue'),
                meta: { title: '购买成功', isloa: false }
            },
            {
                path: '/student/career/userinfo',
                name: 'studentCaruif',
                component: () => import('@/views/student/career/userinfo.vue'),
                meta: { title: '完善信息', isloa: false }
            },

            {
                path: '/student/rpt/pdf_preview',
                name: 'pdfPreview',
                component: () => import('@/views/student/rpt/pdf_preview.vue'),
                meta: { title: 'PDF预览', isloa: false }
            },
            {
                path: '/student/career/course_center',
                name: 'course_center',
                component: () => import('@/views/student/career/course_center.vue'),
                meta: { title: '课程中心', isloa: false }
            },
            {
                path: '/student/career/video_review',
                name: 'video_review',
                component: () => import('@/views/student/career/video_review.vue'),
                meta: { title: '视频回放', isloa: false }
            },  {
                path: '/student/career/video_review_play',
                name: 'video_review_play',
                component: () => import('@/views/student/career/video_review_play.vue'),
                meta: { title: '视频播放', isloa: false }
            },

            {
                path: '/student/career/exam_result',
                name: 'exam_result',
                component: () => import('@/views/student/career/exam_result.vue'),
                meta: { title: '考试结果', isloa: false }
            },
            {
                path: '/student/career/exam_question',
                name: 'exam_question',
                component: () => import('@/views/student/career/exam_question.vue'),
                meta: { title: '作答详情', isloa: false }
            },
        ]
    },

    /* 问卷调查 */
    {
        path: '/survey',
        component: () => import('@/views/layout/placeholder'),
        children: [
            {
                path: 'q1/:channel/:code',
                name: 'surveyQuestion1',
                meta: { title: '调查问卷' },
                component: () => import('@/views/survey/question1')
            },
            {
                path: 'q2/:channel/:code',
                name: 'surveyQuestion2',
                meta: { title: '调查问卷' },
                component: () => import('@/views/survey/question2')
            },
        ]
    }

]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})


export default router
